.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;

  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 1000;

  background-color: #0c5b34;
  color: white;
  border-radius: 1rem;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.9s ease-in-out;
}

.closeBannerButton {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  color: white;
  cursor: pointer;
}

.closeBannerButton:hover {
  color: #e0e0e0;
}

.title {
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
}

.body {
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
  padding-top: 0;
}

.button {
  background-color: white;
  color: #0c5b34;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.75rem;
  margin-top: 0.5rem;
}

.button:hover {
  background-color: #e0e0e0;
}

.remmindMeLater {
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.remmindMeLater:hover {
  color: #e0e0e0;
}

/* Animation */
.visible {
  transform: translateX(0);
}

.hidden {
  transform: translateX(200%);
}
